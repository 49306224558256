export const paymentOptionsConfig = {
  wallet: { name: '儲值金', value: 'wallet' },
  newebpay: { name: '藍新支付', value: 'newebpay' },
  jkos: { name: '街口支付', value: 'jkos' },
  linepay: { name: 'LinePay', value: 'linepay' },
  pchomepay: { name: 'PChomePay 支付連', value: 'pchomepay' },
  atm: { name: 'ATM轉帳', value: 'atm' },
  COD: { name: '貨到付款', value: 'COD' },
  'offline-jkos': { name: '街口支付（實體店）', value: 'offline-jkos', type: 'offline' },
  'offline-newebpay': { name: '信用卡（實體店）', value: 'offline-newebpay', type: 'offline' },
  'offline-linepay': { name: 'LinePay（實體店）', value: 'offline-linepay', type: 'offline' },
  'offline-cash': { name: '現金（實體店）', value: 'offline-cash', type: 'offline' },
  free: { name: '免付款', value: 'free', type: 'free' },
  offline: { name: '匯款（實體店）', value: 'offline', type: 'offline' },
}

export const paymentStatusConfig = {
  unpaid: { name: '未付款', value: 'unpaid', tagType: 'warning' },
  paid: { name: '已付款', value: 'paid', tagType: 'action' },
  waitRefund: { name: '等待退款', value: 'waitRefund', tagType: 'warning' },
  refund: { name: '已退款', value: 'refund', tagType: 'info' },
  fail: { name: '付款失敗', value: 'fail', tagType: 'danger' },
  expire: { name: '付款過期', value: 'expire', tagType: 'danger' },
}

export const paymentConfigFields = {
  linepay: ['linePayChannelId', 'linePayChannelSecretKey'],
  jkos: ['jkosStoreId', 'jkosApiKey', 'jkosSercertKey'],
  newebpay: ['newebpayMerchantId', 'newebpayHashKey', 'newebpayHashIV'],
}
